export const offreFlexible = [
    {
        "nom": "formule1",
        "nombreHeures": 10,
        "tarif": 1800,
        "tarifHoraire": 180,
        "nombreSeances": 7,
        "offerts": 0.5
    },
    {
        "nom": "formule2",
        "nombreHeures": 20,
        "tarif": 3200,
        "tarifHoraire": 160,
        "nombreSeances": 14,
        "offerts": 1
    }
];